<template>
  <div style="margin: 20px;">
    <h3>Certificate of Title<a type="text" style="font-size: 14px; color: green;" @click="add()"> New <i class="fas fa-plus-square"/></a></h3>
    <el-card shadow="never">
      <el-row>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-form :inline="true" label-position="right">
            <el-form-item>
              <el-date-picker v-model="year_s" class="custom-input" type="year" size="medium" placeholder="Year" style="width: 100px" clearable />
            </el-form-item>
            <el-form-item>
              <el-select v-model="type_s" class="custom-input" size="medium" placeholder="Type" style="width: 100%" clearable>
                <el-option label="TCT" value="1" />
                <el-option label="OCT" value="2" />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-input v-model="search_input" class="custom-input" size="medium" placeholder="Title/Lot num, Title holder" clearable />
            </el-form-item>
            <el-form-item>
              <el-tooltip content="Search" placement="top">
                <el-button :icon="icon_search" type="primary" size="medium" @click="search()" />
              </el-tooltip>
            </el-form-item>
            <el-form-item>
              <el-tooltip content="Refresh" placement="top">
                <el-button :icon="icon_refresh" type="info" size="medium" @click="refresh()" />
              </el-tooltip>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" align="right">
          <el-form :inline="true" label-position="right">
            <el-form-item>
              <el-checkbox v-model="naf">No Attached File</el-checkbox>
            </el-form-item>
            <el-tooltip content="Export" placement="top">
              <el-button type="info" size="medium" @click="dialogVisible = true"><i class="fas fa-sync fa-print" /></el-button>
            </el-tooltip>
          </el-form>
        </el-col>
      </el-row>
      <el-row>
        <el-table v-loading="loading" ref="singleTable" :data="ct" size="mini" style="width: 100%; font-size: 14px;" highlight-current-row @current-change="handleCurrentChange">
          <el-table-column type="expand" label="..." width="48">
            <template slot-scope="props">
              <el-row>
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                  <p class="para"><strong>Title number: </strong>{{ props.row.title_num }}</p>
                  <p class="para"><strong>Lot number: </strong>{{ props.row.lot_num }}</p>
                  <p class="para"><strong>Type: </strong>{{ props.row.type }}</p>
                  <p class="para"><strong>Title holder: </strong>{{ props.row.title_holder }}</p>
                  <p class="para"><strong>Date issued: </strong>{{ props.row.date_issued }}</p>
                  <p v-if="props.row.created_by_personnel" class="para">
                    <strong>Added by: </strong>
                    <span :title="getFormattedDate(props.row.created_at, 'MMMM Do YYYY, hh:mm:ss a')">{{ props.row.created_by_personnel.name }}</span>
                  </p>
                  <p v-if="props.row.updated_by_personnel" class="para">
                    <strong>Updated by: </strong>
                    <span :title="getFormattedDate(props.row.updated_at, 'MMMM Do YYYY, hh:mm:ss a')">{{ props.row.updated_by_personnel.name }}</span>
                  </p>
                </el-col>
              </el-row>
            </template>
          </el-table-column>
          <el-table-column prop="type" label="Type" width="55px" min-width="55px" />
          <el-table-column prop="title_num" label="Title number" width="" />
          <el-table-column width="" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.path" style="word-break: break-word"><el-tag type="success" effect="plain" size="medium" style="color: #4CAF50 !important; border-color: #4CAF50 !important; width: 120px;">File Uploaded</el-tag></span>
              <span v-else style="word-break: break-word"><el-tag type="danger" effect="plain" size="medium" style="width: 120px;">No Attached File</el-tag></span>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="Operation (s)" width="150" align="right">
            <template slot-scope="scope">
              <el-button-group>
                <el-tooltip v-if="scope.row.path" :key="'iftpct1' + scope.row.id" content="View Attached File" placement="top">
                  <el-button :key="'ifbnct2' + scope.row.id" type="primary" icon="el-icon-document" size="mini" style="margin-right: 10px;" @click="viewFile(scope.row.path)" />
                </el-tooltip>
                <el-tooltip :key="'tpct3' + scope.row.id" content="Edit" placement="top">
                  <el-button :key="'bnct4' + scope.row.id" type="primary" icon="el-icon-edit" size="mini" style="margin-right: 10px;" @click="edit(scope.$index)" />
                </el-tooltip>
              </el-button-group>
            </template>
          </el-table-column>
        </el-table>
        <div align="center" style="margin-top: 10px;">
          <el-pagination :page-size="meta.per_page" :pager-count="5" :total="meta.total" :current-page.sync="meta.page" layout="total, prev, pager, next" @current-change="getAll()" />
        </div>
      </el-row>
    </el-card>
    <el-dialog v-if="selected" :visible.sync="editable" :title="title" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false" top="5vh" width="45%">
      <el-row style="margin: -50px 0;">
        <el-divider />
        <p><strong>Note:</strong> Fields marked with asterisk (<span style="color: #f56c6c; font-weight: bold;">*</span>) are required to be filled.</p>
        <el-form ref="selected" :model="selected" :rules="rules" :inline="true" label-position="top">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
              <el-form-item label="Title number" prop="title_num" style="width: 100%">
                <el-input v-if="editable" v-model="selected.title_num" placeholder="Title number" size="medium" style="width: 100%" clearable />
              </el-form-item>
              <el-form-item label="Lot number" prop="lot_num" style="width: 100%">
                <el-input v-if="editable" v-model="selected.lot_num" placeholder="Lot number" size="medium" style="width: 100%" clearable />
              </el-form-item>
              <el-form-item label="Type" prop="type" style="width: 100%">
                <el-select v-model="selected.type" size="medium" placeholder="Type" style="width: 100%" clearable>
                  <el-option :value="1" label="TCT" />
                  <el-option :value="2" label="OCT" />
                </el-select>
              </el-form-item>
              <el-form-item label="Date issued" prop="date_issued" style="width: 100%">
                <el-date-picker v-if="editable" v-model="selected.date_issued" size="medium" type="date" placeholder="Please pick a date" style="width: 100%;" clearable />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
              <el-form-item label="Title holder" prop="title_holder" style="width: 100%">
                <el-input v-if="editable" v-model="selected.title_holder" placeholder="Title holder" size="medium" style="width: 100%" clearable />
              </el-form-item>
              <el-form-item label="Attached File" style="width: 100%">
                <ct-uploader-component v-if="editable" :path.sync="selected.path" class="my-upload-job" @setAttachment="selected.path = $event" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-divider />
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="medium" @click="submitForm('selected')">Save</el-button>
        <el-button type="danger" size="medium" @click="submitClose('selected')">Cancel</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="view_attach_form"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="closeFile"
      width="60%"
      top="5vh">
      <div>
        <embed :key="view_file" :src="view_file" style="width: 100%; height: 700px;">
      </div>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false" title="Export" top="5vh" width="25%">
      <el-row style="margin: -50px 0;">
        <el-divider />
        <el-form :inline="true" label-position="right">
          <el-col style="display: flex; flex-wrap: wrap; justify-content: center;">
            <el-form-item class="custom-input">
              <el-select v-model="export_s" placeholder="File" style="width: 100px;">
                <el-option v-for="label in export_active" :key="label.value" :label="label.label" :value="label.value" />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-date-picker v-model="year_s" type="year" size="medium" placeholder="Year" clearable style="width: 100px;" />
            </el-form-item>
            <el-form-item>
              <el-select v-model="active_type" size="medium" placeholder="Select" style="width: 130px;">
                <el-option :label="'All'" :value="null" />
                <el-option v-for="(value, key) in type" :key="key" :label="key" :value="value" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <span slot="footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="primary" style="margin-top: 10px" @click="exportData()">Confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<style scoped>
  .custom-input {
    ::placeholder {
      color: #727272;
    }
  }
</style>

<script>
import { CT } from '@/api/records/ct'
import { Notification } from 'element-ui'
import CtUploaderComponent from '@/views/layout/components/Records/CtUploaderComponent'

export default {
  name: 'CT',
  components: { CtUploaderComponent },
  data() {
    return {
      loading: false,
      meta: {
        page: 1
      },
      export_s: 1,
      dialogVisible: false,
      export_active: [
        { value: 1, label: 'PDF' },
        { value: 2, label: 'Excel' }
      ],
      active_type: null,
      type: { TCT: 1, OCT: 2 },
      year_p: null,
      ct: [],
      editable: false,
      search_input: null,
      type_s: null,
      year_s: null,
      title: null,
      naf: false,
      path_s: null,
      selected: {
        title_num: null,
        lot_num: null,
        type: null,
        date_issued: null,
        title_holder: null,
        path: null
      },
      rules: {
        title_num: [
          { required: true, message: 'Title number is required' }
        ],
        type: [
          { required: true, message: 'Type is required' }
        ]
      },
      icon_search: 'el-icon-search',
      icon_refresh: 'el-icon-refresh',
      view_file: null,
      view_attach_form: false,
      base_url: this.$store.getters.base_url,
      token: this.$store.getters.token
    }
  },
  watch: {
    naf: function() {
      this.meta.page = 1
      if (this.naf === true) {
        this.search_input = null
        this.type_s = null
        this.year_s = null
        this.path_s = 1
      } else {
        this.search_input = null
        this.type_s = null
        this.year_s = null
        this.path_s = null
      }
      this.getAll()
    }
  },
  created: function() {
    this.getAll()
  },
  methods: {
    getAll() {
      this.loading = true
      this.selected = null
      if (this.year_s !== null) {
        this.year_s = this.getFormattedYear(this.year_s)
      }
      CT({
        page: this.meta.page,
        search: this.search_input,
        path: this.path_s,
        year: this.year_s,
        type: this.type_s
      }).then(res => {
        this.ct = res.data.ct
        this.meta = res.data.meta
        this.loading = false
        this.icon_search = 'el-icon-search'
        this.icon_refresh = 'el-icon-refresh'
      }).catch(error => {
        console.log(error)
        this.loading = false
      })
    },
    search() {
      this.icon_search = 'el-icon-loading'
      this.meta.page = 1
      this.getAll()
    },
    refresh() {
      this.icon_refresh = 'el-icon-loading'
      this.search_input = null
      this.naf = false
      this.path_s = null
      this.type_s = null
      this.year_s = null
      this.meta.page = 1
      this.getAll()
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.save()
        } else {
          Notification.error({
            title: 'Error',
            message: 'Unable to save',
            duration: 5 * 1000
          })
          return false
        }
      })
    },
    submitClose(formName) {
      this.$refs[formName].resetFields()
      this.editable = false
    },
    // viewFile(file) {
    //   window.open(this.base_url + '/records/ct/download/' + file, '_blank')
    // },
    viewFile(path) {
      this.view_file = this.base_url + '/records/ct/download/' + path
      this.view_attach_form = true
    },
    closeFile() {
      this.view_file = null
      this.view_attach_form = false
    },
    add() {
      this.title = 'New Certificate of Title'
      this.selected = {}
      this.selected.orig_path = null
      this.editable = true
    },
    edit(index) {
      this.title = 'Edit Certificate of Title'
      this.selected = Object.assign({}, this.ct[index])
      this.selected.type = this.type[this.selected.type]
      this.selected.orig_path = this.selected.path
      this.editable = true
    },
    save: function() {
      const action = this.selected.id ? 'update' : 'create'
      CT(this.selected, action).then(res => {
        if (res.data.error === true) {
          Notification.error({
            title: 'Error',
            message: 'Data already existed',
            duration: 5 * 1000
          })
        } else {
          Notification.success({
            title: 'Success',
            message: 'Transfer Certificate of Title has been saved successfully',
            duration: 5 * 1000
          })
          this.submitClose('selected')
          this.getAll()
        }
      }).catch(error => {
        console.log(error)
      })
    },
    getFormattedYear(year) {
      return this.$moment(new Date(year)).format('YYYY')
    },
    getFormattedDate(date, format = 'YYYY-MM-DD') {
      return this.$moment(date).format(format)
    },
    handleCurrentChange(val) {
      this.currentRow = val
    },
    generateInventory(year, active) {
      if (year != null) {
        window.open(this.base_url + '/records/records_inventory/' + year + '/' + active + '/' + '?token=' + this.token, '_blank')
      } else {
        window.open(this.base_url + '/records/records_list_inventory/' + active + '/' + '?token=' + this.token, '_blank')
      }
    },
    generateExcel(year, active) {
      window.open(`${this.base_url}/records/inventoryCSV/${year}/${active}?token=${this.token}`, '_blank')
    },
    exportData() {
      // switch (this.export_s) {
      //   case 1:
      //     this.generateInventory(this.year_p ? this.getFormattedYear(this.year_p) : null, this.active_p)
      //     break
      //   case 2:
      //     this.generateExcel(this.year_p ? this.getFormattedYear(this.year_p) : null, this.active_p)
      //     break
      // }
      switch (this.export_s) {
        case 1:
          window.open(this.base_url + '/certificate_of_title/pdf/' + (this.year_s !== null ? this.getFormattedYear(this.year_s) : null) + '/' + this.active_type + '/' + '?token=' + this.token, '_blank')
          break
        case 2:
          window.open(this.base_url + '/certificate_of_title/csv/' + (this.year_s !== null ? this.getFormattedYear(this.year_s) : null) + '/' + this.active_type + '/' + '?token=' + this.token, '_blank')
          break
      }
    }
  }
}
</script>
